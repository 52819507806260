<template>
  <div>
    <b-card>
      <page-header name="Seleção de promoção e sorteio" />

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.promotion"
                name="promotion"
                rules="required"
                label="Promoção"
                :options="promotionList"
                @onChange="onSetPromotion"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.prize_draw"
                name="prize_draw"
                rules="required"
                label="Sorteio"
                :options="prizeDrawList"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              class="text-right"
            >
              <b-button
                variant="primary"
                type="submit"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                Salvar
              </b-button></b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapActions } from 'vuex'

export default {
  name: 'ChangePromotion',
  components: {
    ValidationObserver,
  },
  data() {
    return {
      required,
      promotionList: [],
      prizeDrawList: [],
      model: {
        promotion: {},
        prize_draw: {},
      },
    }
  },
  async mounted() {
    const localUser = await this.me()
    if (localUser.current_prize_draw) {
      this.model.prize_draw = {
        value: localUser.current_prize_draw.uuid,
        text: `${localUser.current_prize_draw.name} Sorteio em ${localUser.current_prize_draw.draw_date}`,
      }
    }
    if (localUser.current_promotion) {
      this.model.promotion = {
        value: localUser.current_promotion.uuid,
        text: localUser.current_promotion.name,
      }
    }
    this.getPromotions()
  },
  methods: {
    ...mapActions('auth', ['me']),
    async onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          const data = {
            promotion_uuid: this.model.promotion.value,
            prize_draw_uuid: this.model.prize_draw.value,
          }
          const response = await this.$http.post('set-current-promotion', data)
          if (response.error) {
            this.$notify.error(response.message)
            return
          }
          this.$notify.success('Promoção definida com sucesso!')
          await this.me()
          const ref = this.$route.query.ref || ''
          if (ref !== '') {
            this.$router.push(ref)
          }
        }
      })
    },
    resetForm() {
      return true
    },
    onSetPromotion(promotion) {
      this.getPrizeDraws(promotion.value)
    },
    async getPromotions() {
      const response = await this.$http.get('/promotions')
      let promotions = []
      response.forEach(promotion => {
        if (promotion.is_archived === false) {
          promotions.push({
            value: promotion.uuid,
            text: promotion.name,
          })
        }
      })
      this.promotionList = promotions
    },
    async getPrizeDraws(uuid) {
      const response = await this.$http.get(`/promotions/${uuid}/prize-draws`)
      this.prizeDrawList = response.map(prizeDraw => ({
        value: prizeDraw.uuid,
        text: `${prizeDraw.name} Sorteio em ${prizeDraw.draw_date}`,
      }))
    },
  },

}
</script>

<style scoped>
.prize_draw_text_color_info {
  color: var(--prize_draw_info_text)
}
</style>
